export const defaultRoleColor = "#ADD8E6";

export function getContrastColor(hexColor) {
  const rgb = parseInt(hexColor.slice(1), 16); // Convert hex to integer
  const r = (rgb >> 16) & 0xff; // Extract red component
  const g = (rgb >> 8) & 0xff;  // Extract green component
  const b = rgb & 0xff;         // Extract blue component
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance > 150 ? '#4e4e4e' : '#FFFFFF';
}

export function getValueByPath(obj, path) {
  return path.split('.').reduce((acc, current) => acc ? acc[current] : undefined, obj);
}



export function numberSort({ array, properties, keepTop = (element => false), keepLast = (element => false) }) {
  return [
    ...array.filter(element => keepTop(element) && !keepLast(element)),
    ...array.filter(element => !keepTop(element) && !keepLast(element)).map(element => ({
      ...element,
      sortValue: properties.map(property =>
        getValueByPath(element, property) ?? ''
      ).join('')
    })).sort(numberSortCompare),
    ...array.filter(element => keepLast(element))];
}

export function alphabetSort({ array, properties, keepTop = (element => false), keepLast = (element => false) }) {
  return [
    ...array.filter(element => keepTop(element) && !keepLast(element)),
    ...array.filter(element => !keepTop(element) && !keepLast(element)).map(element => ({
      ...element,
      sortValue: properties.map(property =>
        getValueByPath(element, property) ?? ''
      ).join('')
    })).sort(alphabetSortCompare),
    ...array.filter(element => keepLast(element))];
}

export function inplaceSort({ array, properties }) {
  array.forEach(element => element.sortValue = properties.map(property =>
    getValueByPath(element, property) ?? '').join('')
  ).sort(alphabetSortCompare);
}

export const alphabetSortCompare = (a, b) => a.sortValue.toLowerCase().localeCompare(b.sortValue.toLowerCase());

export const numberSortCompare = (a, b) => a.sortValue - b.sortValue;

export const roleLabels = { "regular_user": "Custom", "lead": "Super", "admin": "Root" }

export const getRoleLabel = (role) => roleLabels[role.type];

export const setSearchValues = (array, searchBy = ['name']) => array.map(option =>
({
  ...option,
  searchValues: searchBy.map(property =>
    property.includes("&") ?
      property.split("&").map(splitProperty => option[splitProperty] ?? '').join(' ').toLowerCase() :
      option[property]?.toString().toLowerCase() ?? '')
})
)

export const searchBy = ({ array, query, hide = false }) => hide ?
  array.map(element => ({ ...element, hidden: !(element.searchValues?.some(value => value.includes(query) ?? true)) }))
  :
  array.filter(element =>
    element.searchValues?.some(value => value.includes(query) ?? true)
  );

export function groupByProperty(arr, property) {
  return arr.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export function arraysHaveSameNumbers(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every(num => arr2.includes(num));
}

export function searchInFolder(folder, query) {
  query = query.toLowerCase(); // Convert query to lowercase for case-insensitive search
  const folderMatches = folder.name.toLowerCase().includes(query);
  const roleMatches = (folder.roles || []).some(role => role.name.toLowerCase().includes(query));
  const subfolderMatches = (folder.folders || []).some(subfolder => searchInFolder(subfolder, query));
  return folderMatches || roleMatches || subfolderMatches;
}

export function sortTree({ baseElement, nestingProperty = "folders", elementsProperty = "files", sortBy = ["name"] }) {
  return {
    ...baseElement,
    [nestingProperty]: alphabetSort({
      array: baseElement[nestingProperty].map(nestedElement => sortTree({ baseElement: nestedElement, nestingProperty, elementsProperty, sortBy })),
      properties: sortBy
    }),
    [elementsProperty]: alphabetSort({ array: baseElement[elementsProperty], properties: sortBy })
  }
}

export function relocateItem(array, fromIndex, targetIndex) {
  if (!checkRange([fromIndex, targetIndex], 0, array.length - 1)) {
    return array;
  }
  let newArray = [...array.slice(0, fromIndex), ...array.slice(fromIndex + 1)];
  return [...newArray.slice(0, targetIndex), array[fromIndex], ...newArray.slice(targetIndex)];
}

export function checkRange(numberArray, minValue, maxValue) {
  return numberArray.length > 0 ? numberArray.every(number => typeof (number) === 'number' && (number >= minValue && number <= maxValue)) : false;
}

export const sortBy = (property) => {
  return (a, b) => a[property] > b[property];
}

function isEqual(value1, value2) {
  return JSON.stringify(value1) === JSON.stringify(value2);
}

export function findChangedElements({ previousVersion, updatedVersion, property, ignoreWhen = () => false }) {
  const previousMap = new Map(previousVersion.map(element => [element.id, element[property]]));
  const changedElements = updatedVersion.filter(element =>
    !isEqual(element[property], previousMap.get(element.id))).filter(element => !ignoreWhen(element)
    );

  return changedElements;
}

export function cleanBy(array, properties) {
  return array.filter(item => properties.every(property => Boolean(item[property])));
}

function setNestedProperty(obj, path, value) {
  const keys = path.split('.');
  const lastKey = keys.pop();

  const newObj = { ...obj };

  keys.reduce((acc, key) => {
    acc[key] = { ...acc[key] };
    return acc[key];
  }, newObj)[lastKey] = value;

  return newObj;
}

export const replaceFalsyValue = (object, property, fallBackProperty) =>
  setNestedProperty(object, property, getValueByPath(object, property) || getValueByPath(object, fallBackProperty))

export const replaceFalsyInArray = (array, property, fallBackProperty) => array.map(item => replaceFalsyValue(item, property, fallBackProperty));

export function testModify(obj) {
  return obj.map(e => ({ ...e, employee: { ...e.employee, userName: null } }));
}

export const filterMap = (arr, filterFn, mapFn) => arr.reduce((acc, curr) => {
  const mapped = mapFn(curr);
  return filterFn(mapped) ? acc.concat(mapped) : acc;
}, []);

export function formatDate(isoString) {
  const dateParts = isoString.split('T')[0].split('-');
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
}

export const indexBy = (array, property) => array.reduce((obj, item) => ({ ...obj, [item[property]]: item }), {});

export function getPreviousSunday(refDate = '') {
  const today = Boolean(refDate) ? new Date(refDate) : new Date();
  return new Date(today - (today.getUTCDay()) * 60 * 60 * 24 * 1000);
}
export function getNextSaturday(refDate = '') {
  const today = Boolean(refDate) ? new Date(refDate) : new Date();
  return new Date(today - (today.getUTCDay() - 6) * 60 * 60 * 24 * 1000);
}
export function getShortIso(date) {
  return date?.toISOString()?.split('T')[0];
}
export function shortPreviousSunday(date = '') {
  return getShortIso(getPreviousSunday(date));
}
export function shortNextSaturday(date = '') {
  return getShortIso(getNextSaturday(date));
}

export const sumArray = (array) => array.reduce((acc, num) => {
  let parsed = parseInt(num);
  let value = isNaN(parsed) ? 0 : parsed;
  return acc + value;
}, 0);

export const objectMap = (obj, callback) => Object.fromEntries(
  Object.entries(obj).map(([key, value]) => [key, callback(value)])
);

export function strToNum(numStr) {
  const number = parseInt(numStr.replace(/\D/g, ''));
  return isNaN(number) ? '' : number;
}

export const WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];