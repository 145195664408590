import React, { useState, useContext } from "react";
import classes from "./EmployeePermissionsContainer.module.scss";
import { VacationScheduling } from "./VacationScheduling";
import { LeaveManagement } from "./LeaveManagement";
import { AddNewVacation } from "./AddNewVacation";
import useUserPermissionState from "../hooks/useUserPermissionState";
import useEmployeeLeaves from "../hooks/useEmployeeLeaves";
import {
  createEmployeeLeave,
  createEmployeeLeaveDeleteShift,
  deleteEmployeeLeave
} from "../services/employeePermissionService";
import { AddNewLeave } from "./AddNewLeave";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";
import InformationDialog from "../UI/InformationDialog";
import userRegisterContext from "../store/user-register-context";
import { useDispatch, useSelector } from "react-redux";
import { resourcePermissions, checkPermission } from "../../features/resourcePepermissionsSlice";
import { selectedLocationState } from "../../features/currentDataSlice";
import { HAS_LEAVES_CODDE, HAS_SHIFT_CODE } from "../services/global";
import { fetchCurrentMonthView } from "../../features/monthViewSlice";

const Permission = ({
  title,
  isOpen,
  onToggle,
  iconClass,
  addMore,
  showAddMore,
  data,
  leaveType,
  onDeleteLeave,
  createActionPermission,
  deleteActionPermission
}) => {
  const toggleArrow = `${isOpen ? `icon-up` : `icon-down`} ${classes.iconArrow
    }`;
  const permissionClass = `${isOpen ? classes.active : ``} ${classes.permission
    }`;

  return (
    <>
      <div className={permissionClass} onClick={onToggle}>
        <div className={classes.titleContainer}>
          <span className={iconClass}></span>
          <span className={classes.title}>{title}</span>
          {isOpen && createActionPermission && (
            <div onClick={(event) => { event.stopPropagation(); addMore() }} className={classes.addVacationContainer}>
              <span className={`icon-more ${classes.icon}`}></span>
            </div>
          )}
        </div>
        <span className={toggleArrow}></span>
      </div>
      {isOpen && !showAddMore && (
        <>
          {title === "Vacation Scheduling" && (
            <VacationScheduling data={data} onDeleteLeave={onDeleteLeave} deleteActionPermission={deleteActionPermission} />
          )}
          {title === "Leave Management" && (
            <LeaveManagement data={data} leaveType={leaveType} onDeleteLeave={onDeleteLeave} deleteActionPermission={deleteActionPermission} />
          )}
          {title === "Sick Leave Tracker" && (
            <LeaveManagement data={data} leaveType={leaveType} onDeleteLeave={onDeleteLeave} deleteActionPermission={deleteActionPermission} />
          )}
          {title === "Training Calendar" && (
            <LeaveManagement data={data} leaveType={leaveType} onDeleteLeave={onDeleteLeave} deleteActionPermission={deleteActionPermission} />
          )}
        </>
      )}
    </>
  );
};

const EmployeePermissionsContainer = ({ employeeId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [onConfirmData, setOnConfirmData] = useState({});
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [infoDialogMsg, setInfoDialogMsg] = useState(false);
  const userRegisterCtx = useContext(userRegisterContext);
  const selectedLocation = useSelector(selectedLocationState);

  const dispatch = useDispatch();
  const {
    employeeLeaves,
    leaves,
    sickLeaves,
    trainingLeaves,
    refetchEmployeeLeaves,
  } = useEmployeeLeaves(employeeId);

  const {
    isVacationOpen,
    isLeaveManagementOpen,
    isSickLeaveOpen,
    isTrainingCalendarOpen,
    addNewVacay,
    addNewLeave,
    addNewSickLeave,
    addNewTraningLeave,
    vacationToggle,
    leaveManagementToggle,
    sickLeaveToggle,
    trainingCalendarToggle,
    addNewVacayOpenToggle,
    addNewLeaveOpenToggle,
    addNewSickLeaveToggle,
    addNewTrainingToggle,
  } = useUserPermissionState();

  const closeAddNewLeaves = () => {
    if (addNewVacay === true) addNewVacayOpenToggle();
    if (addNewLeave === true) addNewLeaveOpenToggle();
    if (addNewSickLeave === true) addNewSickLeaveToggle();
    if (addNewTraningLeave === true) addNewTrainingToggle();
  };

  const closeAddNewLeaveHandler = async (data) => {
    try {
      if (data?.employee) {
        const responseData = await createEmployeeLeave(data);
        if (responseData.responseCode === HAS_SHIFT_CODE) {
          let message = "";
          if (responseData.message.includes(" - ")) {
            message = `Employee has assigned shifts on ${responseData.message}, Do you want to delete them?`;
          } else {
            message = `Employee has assigned shift on ${responseData.message}, Do you want to delete it?`;
          }
          setInfoDialogMsg(message);
          setOnConfirmData(data);
          setShowDialog(true);
        } else if (responseData.responseCode === HAS_LEAVES_CODDE) {
          const message = `Employee has assigned leaves on: ${responseData.message}`;
          setInfoDialogMsg(message);
          setShowInfoDialog(true);
        } else {
          refetchEmployeeLeaves();
          fetchCurrentData(data);
          closeAddNewLeaves();
        }
      } else {
        closeAddNewLeaves();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const closeInfoDialog = () => {
    setShowInfoDialog(false);
  }

  const onCancel = () => {
    setShowDialog(false);
    setOnConfirmData({});
  };

  const fetchCurrentData = (leaveData) => {
    userRegisterCtx.changeWeek(userRegisterCtx.selectedWeek);
    let leaveStartDate = new Date(`${leaveData.startDate}T00:00`);
    dispatch(
      fetchCurrentMonthView({
        monthId: leaveStartDate.getMonth() + 1,
        locationId: selectedLocation.id,
        year:leaveStartDate.getFullYear(),
      })
    );
  };

  const onConfirm = async () => {
    try {
      if (onConfirmData.employee) {
        const responseData = await createEmployeeLeaveDeleteShift(onConfirmData);
        if (responseData) {
          refetchEmployeeLeaves();
        }
        closeAddNewLeaves();
      } else {
        const responseData = await deleteEmployeeLeave(onConfirmData);
        if (responseData.responseCode === 200) {
          refetchEmployeeLeaves();
        }
      }
      setShowDialog(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const onDeleteLeave = (leaveId) => {
    setInfoDialogMsg("Are you sure, you want to delete leave?");
    setOnConfirmData(leaveId);
    setShowDialog(true);
  }

  const currentResourcePermissions = useSelector(resourcePermissions('week-view'));

  return (
    <div className={classes.permissionsContainer}>
      <Permission
        title="Vacation Scheduling"
        isOpen={isVacationOpen}
        onToggle={vacationToggle}
        iconClass={`icon-vacation ${classes.icon}`}
        addMore={() => addNewVacayOpenToggle()}
        showAddMore={addNewVacay}
        data={employeeLeaves}
        onDeleteLeave={onDeleteLeave}
        createActionPermission={checkPermission(currentResourcePermissions, 'schedule-vacation')}
        deleteActionPermission={checkPermission(currentResourcePermissions, 'delete-vacation')}
      />
      {addNewVacay && (
        <AddNewVacation
          closeAddNewVacay={closeAddNewLeaveHandler}
          employeeId={employeeId}
        />
      )}
      <Permission
        title="Leave Management"
        isOpen={isLeaveManagementOpen}
        onToggle={leaveManagementToggle}
        iconClass={`icon-leave ${classes.icon}`}
        addMore={() => addNewLeaveOpenToggle()}
        showAddMore={addNewLeave}
        data={employeeLeaves}
        leaveType={"M"}
        onDeleteLeave={onDeleteLeave}
        createActionPermission={checkPermission(currentResourcePermissions, 'schedule-leave-management')}
        deleteActionPermission={checkPermission(currentResourcePermissions, 'delete-leave-management')}

      />
      {addNewLeave && (
        <AddNewLeave
          leaves={leaves}
          closeAddNewLeave={closeAddNewLeaveHandler}
          employeeId={employeeId}
          type={"M"}
        />
      )}
      <Permission
        title="Sick Leave Tracker"
        isOpen={isSickLeaveOpen}
        onToggle={sickLeaveToggle}
        iconClass={`icon-sick ${classes.icon}`}
        addMore={() => addNewSickLeaveToggle()}
        showAddMore={addNewSickLeave}
        data={employeeLeaves}
        leaveType={"S"}
        onDeleteLeave={onDeleteLeave}
        createActionPermission={checkPermission(currentResourcePermissions, 'schedule-sick-leave')}
        deleteActionPermission={checkPermission(currentResourcePermissions, 'delete-sick-leave')}

      />
      {addNewSickLeave && (
        <AddNewLeave
          leaves={sickLeaves}
          closeAddNewLeave={closeAddNewLeaveHandler}
          employeeId={employeeId}
          type={"S"}
        />
      )}
      <Permission
        title="Training Calendar"
        isOpen={isTrainingCalendarOpen}
        onToggle={trainingCalendarToggle}
        iconClass={`icon-training ${classes.icon}`}
        addMore={() => addNewTrainingToggle()}
        showAddMore={addNewTraningLeave}
        data={employeeLeaves}
        leaveType={"T"}
        onDeleteLeave={onDeleteLeave}
        createActionPermission={checkPermission(currentResourcePermissions, 'schedule-training')}
        deleteActionPermission={checkPermission(currentResourcePermissions, 'delete-training')}
      />
      {addNewTraningLeave && (
        <AddNewLeave
          leaves={trainingLeaves}
          closeAddNewLeave={closeAddNewLeaveHandler}
          employeeId={employeeId}
          type={"T"}
        />
      )}
      {showDialog && (
        <DeleteConfirmationDialog
          message={infoDialogMsg}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
      {showInfoDialog && (
        <InformationDialog
          message={infoDialogMsg}
          onCancel={closeInfoDialog}
        />
      )}
    </div>
  );
};

export { EmployeePermissionsContainer };