import React, { useEffect, useState } from "react";
import classes from './ScheduleCreation.module.scss'
import Modal from "../UI/Modal";
import { defaultRoleColor } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";
import ConfirmDelete from "../roleManagement/ConfirmDelete";
import { deleteScheduleTime, saveUpdateSchedule } from "../services/locationAdminService";
import Input from "../UI/Input";

const emptySchedule = { id: null, label: '', color: defaultRoleColor, endTime: '', startTime: '' }
const inputsInfo = { 
  label: { uiLabel: "Label", type: "text" },
  startTime: { uiLabel: "Entry Time", type: "time", icon:"icon-check", iconClassName:classes.startTime }, 
  endTime: { uiLabel: "Departure time", type: "time", icon:"icon-check", iconPosition: 'right', align:'right', iconClassName:classes.endTime }, 
  color: { uiLabel: "Color", type: "color" } 
};

const ScheduleCreation = ({ schedules, reloadSchedules }) => {
  const [openSchedules, setOpenSchedules] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(emptySchedule);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [scheduleBackup, setScheduleBackup] = useState(schedules);
  const dispatch = useDispatch();

  const clearSchedule = () => setSelectedSchedule(emptySchedule);
  const closeDialog = () => setOpenSchedules(false);
  const closeDeleteDialog = () => setDeleteInfo(null);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSelectedSchedule(prev => ({ ...prev, [name]: value }));
  }

  useEffect(()=>{
  },[selectedSchedule])

  const resetSchedule = () => {
    setSelectedSchedule(prev => [...schedules, emptySchedule].find(schedule => schedule.id === prev.id));
  }

  const saveSchedule = () => {
    if (Object.keys(inputsInfo).every(key => (typeof (selectedSchedule[key]) === 'string') && selectedSchedule[key].length > 0)) {
      // ! send request;
      saveUpdateSchedule(selectedSchedule).then(
        response => {
          if (typeof (response?.id) === 'number') {
            setSelectedSchedule(response);
            dispatch(showToast({ type: "success", message: selectedSchedule.id ? `Schedule updated` : "Schedule created", duration: 3000 }))
            reloadSchedules();
          } else {
            throw Error('Something went wrong while saving the schedule');
          }
        }
      ).catch(
        error => {
          resetSchedule();
          dispatch(showToast({ type: "warning", message: error.message, duration: 3000 }));
        }
      );
    } else {
      dispatch(showToast({ type: "info", message: "Please complete all the schedule information to save.", duration: 5000 }))
    }
  }

  const deleteSchedule = () => {
    deleteScheduleTime(deleteInfo).then(
      response => {
        if (response === true) {
          dispatch(showToast({ type: "info", message: "Schedule deleted", duration: 3000 }));
          reloadSchedules();
          setSelectedSchedule(prev => prev.id === deleteInfo.id ? emptySchedule : prev);
        } else {
          throw Error('Something went wrong while deleting the schedule');
        }
      }
    ).catch(
      error => dispatch(showToast({ type: "warning", message: error.message, duration: 3000 }))
    )
  }

  useEffect(() => {
    setSelectedSchedule(emptySchedule);
  }, [openSchedules]);

  useEffect(() => {
    document.querySelector('[class*="highlight"]').scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [schedules]);

  return (
    <>
      <button
        onClick={() => setOpenSchedules(true)}
        className={classes.formBtn}
        type="button"
      >
        <span className={`icon-add-schedule ${classes.iconSite}`}></span>
        Schedule Creation
      </button>

      {openSchedules &&
        <Modal open={openSchedules} onClose={() => setOpenSchedules(false)} className={"scheduleCreation"}>
          <div className={classes.dialogContent}>
            <div className={classes.dialogHeader}>
              <div className={classes.iconTitle}>
                <i className="icon-add-schedule" />
                <span>Schedule Creation</span>
              </div>
              <button className={`${classes.greenButton}`} onClick={clearSchedule}><i className="icon-more" /></button>
            </div>
            <div className={classes.dialogButtons}>
              <button className={`${classes.greenButton} ${classes.textButton}`} onClick={closeDialog}>Done</button>
              <button className={`${classes.iconButton}`} onClick={closeDialog}><i className="icon-close" /></button>
            </div>
            <div className={classes.card}>
              {schedules.length > 0 ?
                <div className={classes.schedules}>
                  {schedules.map((schedule, index) =>
                    <div id={`schedule${schedule.id}`} className={`${classes.schedule} ${schedule.id === selectedSchedule.id ? classes.highlight : ''}`} onClick={() => setSelectedSchedule(schedule)} key={index}>
                      <i className="icon-check-schedule" />
                      <div className={classes.scheduleDetails}>
                        <h1>{schedule.label}</h1>
                        <h2>{`${schedule.startTime}-${schedule.endTime}`}</h2>
                      </div>
                      <div className={classes.scheduleButtons}>
                        <button onClick={() => setDeleteInfo(schedule)}><i className="icon-delete" /></button>
                        <button><i className="icon-right-arrow" /></button>
                      </div>
                    </div>
                  )}

                </div> :
                <div className={classes.placeholder}>
                  <i className="icon-add-schedule" />
                  <span>You haven't created a schedule yet. Please click on the <b>+ Create Schedule</b> button to create one.</span>
                </div>
              }
            </div>
            <div className={classes.card}>
              <div className={classes.rightPanel}>
                <div className={classes.scheduleInfo}>
                  {Object.keys(inputsInfo).map((name, index) =>
                    <Input
                      key={index} 
                      name={name} 
                      value={selectedSchedule[name]} 
                      onChange={onChange} 
                      label={inputsInfo[name].uiLabel} 
                      type={inputsInfo[name].type} 
                      icon={inputsInfo[name].icon}
                      iconClassName={inputsInfo[name].iconClassName}
                      iconPosition={inputsInfo[name].iconPosition}
                      align={inputsInfo[name].align}
                    />)
                  }
                </div>
                <div className={classes.buttons}>
                  {selectedSchedule.id === null ?
                    <button className={classes.greenButton} onClick={saveSchedule}><i className="icon-more" />Create Schedule</button>
                    :
                    <>
                      <button onClick={resetSchedule}>Cancel</button>
                      <button className={classes.greenButton} onClick={saveSchedule}>Save</button>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal>}
      <ConfirmDelete
        open={typeof (deleteInfo?.id) === 'number'}
        onClose={(confirm) => { if (confirm) deleteSchedule(); closeDeleteDialog(); }}
        message={
          `Are you sure you want to delete "${deleteInfo?.label ?? ''}" schedule?`
        }
      />
    </>
  )
}

export default ScheduleCreation;