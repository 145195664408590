import React, { useState, useEffect, useCallback } from "react";
import classes from "./FormNewLocation.module.scss";
import Dropdown from "../UI/Dropdown";
import SwitchButton from "../UI/switchButton";
import { saveNewSite } from "../services/locationAdminService";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../features/toastNotificationSlice";
import ScheduleCreation from "./ScheduleCreation";
import AssignSchedules from "./AssignSchedules";
import SiteSchedules from "./SiteSchedules";
import { indexBy } from "../../utils/utils";

const FormNewLocation = ({
  location,
  saveLocationHandler,
  deleteSiteHandler,
  Allsites,
  schedules,
  reloadSchedules,
  setAllSites,
  AllsitesCopy,
  setAllSitesCopy,
  americaTZ,
}) => {
  const [locationCode, setLocationCode] = useState(location.value || "");
  const [name, setName] = useState(location.label || "");
  const [locationTZ, setLocationTZ] = useState({ name: location.timeZone });
  const [locationSites, setLocationSites] = useState([...location.sites]);
  const [openDialogMsg, setOpenDialogMsg] = useState(false);
  const [siteToBeDeleted, setSiteToBeDeleted] = useState({});
  const [indexSiteToBeDeleted, setIndexSiteToBeDeleted] = useState(-1);
  const [assingTo, setAssignTo] = useState(null);
  const [siteSchedules, setSiteSchedules] = useState(new Set());
  const [siteScheduleBackup, setSiteScheduleBackup] = useState(new Set());
  const dispatch = useDispatch();
  const idxSchedules = indexBy(schedules, 'id');

  useEffect(() => {
    const scheduleBySiteId = location.sites.reduce((siteObj, site) => ({ ...siteObj, [site.id]: new Set(site.schedules) }), {});
    setSiteSchedules(scheduleBySiteId);
    setSiteScheduleBackup(scheduleBySiteId);
  }, [location]);


  const updateSiteSchedules = (scheduleIds, siteId) => setSiteSchedules(prev => {
    return { ...prev, [siteId]: scheduleIds };
  });

  const resetSiteSchedules = useCallback((siteId) => {
    setSiteSchedules(prev => ({ ...prev, [siteId]: siteScheduleBackup[siteId] }))
  }, [siteScheduleBackup, setSiteSchedules]);

  useEffect(() => {
    setLocationCode(location.value || location.locationCode || "");
    setName(location.label || location.name || "");
    setLocationTZ({ name: location.timeZone } || "");
    if (location.sites.length > 0) {
      setLocationSites(location.sites);
      const updatedSites = Allsites.map((site) => {
        const matchingSite = location.sites.find(
          (element) => element.id === site.id
        );
        if (matchingSite) {
          return { ...site, isActive: true, isEditing: false };
        } else {
          return { ...site, isActive: false, isEditing: false };
        }
      });
      setAllSites(updatedSites);
      setAllSitesCopy(updatedSites);
    } else {
      const updatedSites = Allsites.map((site) => ({
        ...site,
        isActive: false,
        isEditing: false,
      }));
      setAllSites(updatedSites);
      setAllSitesCopy(updatedSites);
      setLocationSites(location.sites);
    }
  }, [location]);

  const allSitesReference = useRef();

  useEffect(() => {
    scrollToNewSite(allSitesReference?.current ?? Allsites, Allsites);
    allSitesReference.current = Allsites;
  }, [Allsites])

  const handleLocationCodeChange = (e) => {
    setLocationCode(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const selectTimeZoneHandler = (timeZone) => {
    setLocationTZ(timeZone);
  };

  const handleAddSite = (e) => {
    e.preventDefault();
    setAllSites([
      ...Allsites,
      { siteCode: "", name: "", isEditing: false, isActive: false },
    ]);
    setAllSitesCopy([
      ...Allsites,
      { siteCode: "", name: "", isEditing: false, isActive: false },
    ]);
  };

  const handleDeleteSite = (index, site) => {
    setOpenDialogMsg(true);
    setIndexSiteToBeDeleted(index);
    setSiteToBeDeleted(site);
  };

  const onConfirmDelete = async () => {
    if (siteToBeDeleted && indexSiteToBeDeleted !== -1) {
      if (siteToBeDeleted.id) {
        const response = await deleteSiteHandler(siteToBeDeleted.id, locationSites);
        if (response.success) {
          dispatch(showToast({ type: 'success', message: `Site "${siteToBeDeleted.name}" deleted successfully` }))
        }
      }
    }
    setSiteToBeDeleted({});
    setIndexSiteToBeDeleted(-1);
    setOpenDialogMsg(false);
  };

  const onCancel = () => {
    setOpenDialogMsg(false);
  };

  const handleSiteCodeChange = (index, value) => {
    const updatedSites = Allsites.map((site, i) =>
      i === index ? { ...site, siteCode: value } : site
    );
    setAllSites(updatedSites);
  };

  const handleSiteNameChange = (index, value) => {
    const updatedSites = Allsites.map((site, i) =>
      i === index ? { ...site, name: value } : site
    );
    setAllSites(updatedSites);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Boolean(locationCode) && Boolean(name) && Boolean(locationTZ?.name)) {
      const data = {
        id: location.id ? location.id : null,
        locationCode: locationCode,
        name: name,
        sites: locationSites,
        timeZone: locationTZ.name,
        scheduleTimes: locationSites.flatMap(
          site => Array.from(siteSchedules[site.id] ?? [], (scheduleId) => idxSchedules[scheduleId]).map(scheduleTime => ({ site, scheduleTime }))
        )
      };
      saveLocationHandler(data);
    } else {
      dispatch(showToast({ type: 'info', message: "Please complete all required fields." }))
    }
  };

  const handleEditSite = (index) => {
    const updatedAllSites = [...Allsites];
    updatedAllSites[index] = { ...updatedAllSites[index], isEditing: true };
    setAllSites(updatedAllSites);
  };

  const handleSaveSite = async (site, index) => {
    const obj = { id: site.id, name: site.name, siteCode: site.siteCode };
    try {
      const response = await saveNewSite(obj);
      if (response.responseCode === 200) {
        const updatedAllSites = [...Allsites];
        updatedAllSites[index] = {
          ...response.load,
          isEditing: false,
          isActive: updatedAllSites[index].isActive
            ? updatedAllSites[index].isActive
            : false,
        };
        setAllSites(updatedAllSites);
        setAllSitesCopy(updatedAllSites);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const closeEditingSite = (index) => {
    const updatedAllSites = [...AllsitesCopy];
    updatedAllSites[index] = { ...AllsitesCopy[index], isEditing: false };
    // Restore the original site data
    setAllSites([...updatedAllSites]);
    setAllSitesCopy([...updatedAllSites]);
  };

  const activeSwitchHandler = (newValue, index) => {
    const updatedAllSites = [...Allsites];
    if (updatedAllSites[index].id) {
      updatedAllSites[index] = {
        ...updatedAllSites[index],
        isActive: newValue,
      };
      setAllSites(updatedAllSites);
      setAllSitesCopy(updatedAllSites);
      if (newValue && !locationSites.find(l => l.id === updatedAllSites[index].id)) {
        // Add it to the location site
        const newLocationObj = {
          id: updatedAllSites[index].id,
          label: updatedAllSites[index].name,
          name: updatedAllSites[index].name,
          siteCode: updatedAllSites[index].siteCode,
        };
        setLocationSites([...locationSites, newLocationObj]);
      } else {
        // Delete it from the location sites
        const updateLocationSites = locationSites.filter(
          (ele) => ele.id !== updatedAllSites[index].id
        );
        setLocationSites(updateLocationSites);
      }
    }
  };

  const toggleAllSites = (active) => {
    const updatedAllSites = [...Allsites.map(site => ({ ...site, isActive: active }))];
    setAllSites(updatedAllSites);
    setAllSitesCopy(updatedAllSites);
    setLocationSites(active ? Allsites : []);
  }

  return (
    <>
      <form className={classes.newLocationForm} onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.header}>
          <div className={classes.title}>
            <span className={`icon-location ${classes.iconLocation}`} />
            {location.id && <span>Locations</span>}
            {!location.id && <span>Create new location</span>}
          </div>
          <button id="subtmitBtn" type="submit" className={`${classes.formBtn} ${classes.saveBtn}`}>
            Save
          </button>
        </div>
        <div className={classes.formHeader}>
          <div className={classes.formGroup}>
            <label htmlFor="locationCode">Location Code <RequiredMarker /></label>
            <input
              className={classes.inputForm}
              type="text"
              id="locationCode"
              value={locationCode}
              onChange={handleLocationCodeChange}
            />
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="name">Name <RequiredMarker /></label>
            <input
              className={classes.inputForm}
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
            />
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="locationCode">Time Zone <RequiredMarker /></label>
            <div className={classes.dropdownContainer}>
              <Dropdown
                className="locationForm"
                icon={"icon-location"}
                iconClass={"location"}
                defaultValue={locationTZ}
                options={americaTZ}
                onClick={selectTimeZoneHandler}
                showUserInfo={false}
                showSearch={true}
                contentHeight={"50%"}
                theme={'light'}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className={classes.sites}>
          <div className={classes.sitesHeader}>
            <div className={classes.sitesHeaderButtons}>
              <div className={classes.sitesTitle}>
                <span className={`icon-site ${classes.iconSite}`}></span>
                <span className={classes.siteTitle}>Sites</span>
              </div>
              <button
                onClick={(e) => handleAddSite(e)}
                className={classes.formBtn}
              >
                <span className={`icon-add-site ${classes.iconSite}`}></span>
                New Site
              </button>
              <ScheduleCreation schedules={schedules} reloadSchedules={reloadSchedules} />
            </div>
            <div className={classes.sitesTitle}>
              <label>Enable All sites</label>
              <SwitchButton defaultChecked={Allsites.every(site => site.isActive)} onChange={toggleAllSites} disabledColor="#000000" />
            </div>
          </div>
          <div className={classes.siteContainer} id={"sitesContainer"}>
            {Allsites?.map((site, index) => (
              <div className={`${classes.siteCard} ${assingTo?.id === site.id ? classes.highlightSite : ''}`} key={index}>
                <label htmlFor={`siteCode${index}`}>Code</label>
                <input
                  className={`${classes.inputForm} ${classes.siteCode}`}
                  id={`siteCode${index}`}
                  type="text"
                  value={site.siteCode}
                  onChange={(e) => handleSiteCodeChange(index, e.target.value)}
                  disabled={!site.isEditing}
                />
                <label htmlFor={`siteName${index}`}>Name</label>
                <input
                  className={`${classes.inputForm} ${classes.siteName}`}
                  id={`siteName${index}`}
                  type="text"
                  value={site.name}
                  onChange={(e) => handleSiteNameChange(index, e.target.value)}
                  disabled={!site.isEditing}
                />
                <SiteSchedules schedules={schedules} enabledSchedules={siteSchedules[site.id] ?? new Set()} />
                {!site.isEditing && (
                  <>
                    <AssignSchedules
                      className={classes.deleteIcon}
                      schedules={schedules}
                      enabledSchedules={siteSchedules[site.id] ?? new Set()}
                      site={site}
                      onOpen={(siteParameter) => setAssignTo(siteParameter)}
                      updateSiteSchedules={updateSiteSchedules}
                      resetSiteSchedules={resetSiteSchedules}
                    />
                    <div
                      onClick={() => handleEditSite(index)}
                      className={classes.deleteIcon}
                    >
                      <span className="icon-draft"></span>
                    </div>
                    <div
                      onClick={() => handleDeleteSite(index, site)}
                      className={classes.deleteIcon}
                    >
                      <span className="icon-delete"></span>
                    </div>

                    <div className={classes.switchContainer}>
                      <SwitchButton
                        onChange={(newValue) => {
                          activeSwitchHandler(newValue, index);
                        }}
                        defaultChecked={site.isActive}
                        disabled={!site.id}
                      />
                    </div>
                  </>
                )}
                {site.isEditing && (
                  <>
                    <div
                      className={`${classes.deleteIcon} ${classes.editingText}`}
                    >
                      <span>Editing...</span>
                    </div>
                    <div
                      onClick={() => handleSaveSite(site, index)}
                      className={classes.deleteIcon}
                    >
                      <span className="icon-check"></span>
                    </div>
                    <div
                      onClick={() => closeEditingSite(index)}
                      className={classes.deleteIcon}
                    >
                      <span className="icon-close"></span>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </form>
      {openDialogMsg && (
        <DeleteConfirmationDialog
          message={
            "Would you like to delete the selected site? This action will impact all locations where this site is currently in use."
          }
          onConfirm={onConfirmDelete}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

const RequiredMarker = () => <span className={classes.requiredMarker}>*</span>;

function scrollToNewSite(previousSites, currentSites) {
  if (currentSites?.length === (previousSites?.length ?? -2) + 1) {
    let newSite = document.getElementById('sitesContainer')?.lastChild;
    if (newSite) {
      newSite.scrollIntoView();
      newSite.classList.add(classes.blinkEffect);
      setTimeout(() => {
        newSite.classList.remove(classes.blinkEffect);
      }, 1800);
    }
  }
}

export default FormNewLocation;
