import { useState, useEffect, useCallback } from "react";
import { getSites, getScheduleTimes } from "../services/userRegisterService";
import useSchedules from "./useSchedules";

const useScheduleTimesAndSites = (userShift, userCtx) => {
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  // const [schedules, setSchedules] = useState([]);
  const [schedules, _] = useSchedules();
  const [selectedSchedule, setSelectedSchedule] = useState({});

  const reloadSites = useCallback(async ()=>{
    try {
      const dataSites = await getSites();
      if(dataSites && dataSites.load) {
        const loadedSites = dataSites.load.map(site => ({...site, labelDropdown: site.name}));
        setSites(loadedSites)
      } 
    } catch (error) {
      console.log(error.message)
    }
  }, []);

  useEffect(() => {
    const fetchScheduleTimesAndSites = async () => {
      try {
        if (userCtx.sites.length === 0) {
          const dataSites = await getSites();
          if (dataSites && dataSites.load) {
            const loadedSites = [];
            for (const key in dataSites.load) {
              loadedSites.push({
                ...dataSites.load[key],
                labelDropdown: dataSites.load[key].name,
              });
            }
            setSites(loadedSites);
            if (!userShift?.site) {
              setSelectedSite(loadedSites[0]);
            }
          }
        } else {
          setSites(userCtx.sites);
          if (!userShift?.site) {
            setSelectedSite(userCtx.sites[0]);
          }
        }
        if (userShift?.site) {
          setSelectedSite({
            ...userShift.site,
            labelDropdown: userShift.site.name
          });
        }
        if (userCtx.scheduleTimes.length === 0) {
          const dataSchedule = await getScheduleTimes();
          if (dataSchedule && dataSchedule.load) {
            const loadedSchedules = [];
            for (const key in dataSchedule.load) {
              const label =
                dataSchedule.load[key].label +
                " " +
                dataSchedule.load[key].startTime +
                " - " +
                dataSchedule.load[key].endTime;
              loadedSchedules.push({
                ...dataSchedule.load[key],
                labelDropdown: label,
                labelShortName: dataSchedule.load[key].label
              });
            }
            // setSchedules(loadedSchedules);
            if (!userShift?.scheduleTime) {
              setSelectedSchedule(loadedSchedules[0]);
            }
          }
        } else {
          // setSchedules(userCtx.scheduleTimes);
          if (!userShift?.scheduleTime) {
            setSelectedSchedule(userCtx.scheduleTimes[0]);
          }
        }
        if (userShift?.scheduleTime) {
          const label =
            userShift.scheduleTime.label +
            " " +
            userShift.scheduleTime.startTime +
            " - " +
            userShift.scheduleTime.endTime;
          setSelectedSchedule({
            ...userShift.scheduleTime,
            labelDropdown: label,
            labelShortName: userShift.scheduleTime.label
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchScheduleTimesAndSites();
  }, [userShift, userCtx]);
  return {
    sites,
    selectedSite,
    schedules,
    selectedSchedule,
    setSelectedSite,
    setSelectedSchedule,
    reloadSites
  };
};

export default useScheduleTimesAndSites;