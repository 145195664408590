import { useState } from "react";

const useUserPermissionState = () => {
  const [isVacationOpen, setIsVacationOpen] = useState(false);
  const [isLeaveManagementOpen, setIsLeaveManagementOpen] = useState(false);
  const [isSickLeaveOpen, setIsSickLeaveOpen] = useState(false);
  const [isTrainingCalendarOpen, setIsTrainingCalendarOpen] = useState(false);
  const [addNewVacay, setAddNewVacay] = useState(false);
  const [addNewLeave, setAddNewLeave] = useState(false);
  const [addNewSickLeave, setAddNewSickLeave] = useState(false);
  const [addNewTraningLeave, setAddNewTraningLeave] = useState(false);

  const addNewVacayOpenToggle = () => {
    setAddNewVacay(!addNewVacay)
  }

  const addNewLeaveOpenToggle = () => {
    setAddNewLeave(!addNewLeave);
  }

  const addNewSickLeaveToggle = () => {
    setAddNewSickLeave(!addNewSickLeave);
  }

  const addNewTrainingToggle = () => {
    setAddNewTraningLeave(!addNewTraningLeave);
  }

  const vacationToggle = () => {
    setIsVacationOpen(!isVacationOpen);
    if (!isVacationOpen === true) {
      if (isLeaveManagementOpen === true) setIsLeaveManagementOpen(false);
      if (isSickLeaveOpen === true) setIsSickLeaveOpen(false);
      if (isTrainingCalendarOpen === true) setIsTrainingCalendarOpen(false);
      if (addNewLeave === true) setAddNewLeave(false);
      if (addNewSickLeave === true) setAddNewSickLeave(false);
      if (addNewTraningLeave === true) setAddNewTraningLeave(false);
    } else {
        setAddNewVacay(false);
    }
  };

  const leaveManagementToggle = () => {
    setIsLeaveManagementOpen(!isLeaveManagementOpen);
    if (!isLeaveManagementOpen === true) {
        if (isVacationOpen === true) setIsVacationOpen(false);
        if (isSickLeaveOpen === true) setIsSickLeaveOpen(false);
        if (isTrainingCalendarOpen === true) setIsTrainingCalendarOpen(false);
        if (addNewVacay === true) setAddNewVacay(false);
        if (addNewSickLeave === true) setAddNewSickLeave(false);
        if (addNewTraningLeave === true) setAddNewTraningLeave(false);
    } else {
      setAddNewLeave(false);
    }
  };

  const sickLeaveToggle = () => {
    setIsSickLeaveOpen(!isSickLeaveOpen);
    if (!isSickLeaveOpen === true) {
        if (isVacationOpen === true) setIsVacationOpen(false);
        if (isLeaveManagementOpen === true) setIsLeaveManagementOpen(false);
        if (isTrainingCalendarOpen === true) setIsTrainingCalendarOpen(false);
        if (addNewVacay === true) setAddNewVacay(false);
        if (addNewLeave === true) setAddNewLeave(false);
        if (addNewTraningLeave === true) setAddNewTraningLeave(false);

    } else {
      setAddNewSickLeave(false)
    }
  };

  const trainingCalendarToggle = () => {
    setIsTrainingCalendarOpen(!isTrainingCalendarOpen);
    if (!isTrainingCalendarOpen === true) {
        if (isVacationOpen === true) setIsVacationOpen(false);
        if (isLeaveManagementOpen === true) setIsLeaveManagementOpen(false);
        if (isSickLeaveOpen === true) setIsSickLeaveOpen(false);
        if (addNewVacay === true) setAddNewVacay(false);
        if (addNewLeave === true) setAddNewLeave(false);
        if (addNewSickLeave === true) setAddNewSickLeave(false);
    } else {
      setAddNewTraningLeave(false);
    }
  };

  return {
    isVacationOpen,
    isLeaveManagementOpen,
    isSickLeaveOpen,
    isTrainingCalendarOpen,
    addNewVacay,
    addNewLeave,
    addNewSickLeave,
    addNewTraningLeave,
    setAddNewVacay,
    vacationToggle,
    leaveManagementToggle,
    sickLeaveToggle,
    trainingCalendarToggle,
    addNewVacayOpenToggle,
    addNewLeaveOpenToggle,
    addNewSickLeaveToggle,
    addNewTrainingToggle
  };
};

export default useUserPermissionState;