import UserRegister from "../Users/UserRegister";
import classes from "./MonthViewDays.module.scss";
import WorkShiftConfig from "../Users/WorkShiftConfig";
import useWeekDaysState from "../hooks/useWeekDaysState";
import { useSelector } from "react-redux";
import { resourcePermissions, checkPermission } from "../../features/resourcePepermissionsSlice";
import { allLocations } from "../../features/currentDataSlice";
import { Tooltip } from "@mui/material";
import { MONTHVIEW_ID } from "../services/global";
import LeaveSlot from "../standard/LeaveSlot";
import WorkShiftConfig2 from "../Users/WorkShiftConfig2";
const MonthViewDays = ({ shifts, greyBackground, isUserTransfer, locationId }) => {

  const {
    isWorkShiftShown,
    userShift,
    user,
    shiftIndex,
    showWorkShiftHandler,
    hideShowWorkShiftHandler,
  } = useWeekDaysState(shifts);

  const currentResourcePermissions = useSelector(resourcePermissions('month-view'));
  const loadedLocations = useSelector(allLocations);

  const openShifts = (shift, index) => {
    if (isUserTransfer) {
      if (shift.avaibleInTransfer) {
        if (shift.id) {
          showWorkShiftHandler(shift, index);
        } else {
          if (checkPermission(currentResourcePermissions, 'create-shift'))
            showWorkShiftHandler(shift, index);
        }
      }
    } else if (!shift.leave && !shift.transferTo) {
      if (shift.id) {
        showWorkShiftHandler(shift, index);
      } else {
        if (checkPermission(currentResourcePermissions, 'create-shift'))
          showWorkShiftHandler(shift, index);
      }
    } 
  }

  return (
    <>
      {shifts.shifts &&
        shifts.shifts.map((shift, index) => {
          const transferLocation = loadedLocations.find(
            (obj) => obj.id === shift?.transferTo
          ) ?? loadedLocations.find(obj => obj.id === locationId);
          
          const onSlotClick = () => showWorkShiftHandler(shift, index);
          return (
            <div
              key={index}
              onClick={() => { openShifts(shift, index) }}
              className={`${shift.scheduleTime && shift.site
                ? classes.slot
                : !shift.leave
                  ? classes.emptySlot
                  : classes.leaveSlot} ${classes.borderBottom} 
                  ${greyBackground ? classes.greyBackground : ''}
                  ${(index % 14 === 0) ? classes.divisorLine : ''}`
              }
              id="weekDayEmptySlot"
            >
              {shift.scheduleTime &&
                shift.site &&
                !shift.leave && <UserRegister register={shift} monthView={true} />}
              {!shift.scheduleTime &&
                !shift.site &&
                !shift.leave &&
                !shift.transferTo && !isUserTransfer && (
                  <div></div>
                )}
              {isUserTransfer && !shift.scheduleTime &&
                !shift.site &&
                !shift.leave &&
                !shift.transferTo && shift.avaibleInTransfer && (
                  <div></div>
                )}
              {shift.transferTo &&
                transferLocation &&
                !shift.scheduleTime &&
                !shift.site &&
                !shift.leave && (
                  <div className={classes.transferContainer}>
                    <Tooltip
                      title={<div style={{ fontSize: "12px" }}>Relocated {transferLocation?.label ? `to ${transferLocation.label}`: ''}</div>}
                      placement="bottom"
                      arrow
                      PopperProps={{
                        popperOptions: {
                          modifiers: {
                            offset: {
                              enabled: true,
                              offset: '-10px, 0px', // Adjust the offset values as per your requirement
                            },
                          },
                        },
                      }}
                    >
                      <div className={classes.transferContent}>
                      <span
                        className={`icon-icon-relocated ${classes.iconRelocated}`}
                      ></span>
                      <span className={classes.label}>{transferLocation?.value ?? ''}</span>
                      </div>
                    </Tooltip>
                  </div>
                )}
              {!shift.avaibleInTransfer && !(shift.scheduleTime &&
                shift.site ) && isUserTransfer && (
                <div className={classes.transferContainer}>
                <Tooltip
                  title={<div style={{ fontSize: "12px" }}>Origin {transferLocation?.label ? `: ${transferLocation.label}`: ''}</div>}
                  placement="bottom"
                  arrow
                  PopperProps={{
                    popperOptions: {
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: '-10px, 0px', // Adjust the offset values as per your requirement
                        },
                      },
                    },
                  }}
                >
                  <div className={classes.transferContent}>
                  <span
                    className={`icon-icon-relocated ${classes.iconRelocated}`}
                  ></span>
                  <span className={classes.label}>{transferLocation?.value ?? ''}</span>
                  </div>
                </Tooltip>
              </div>
              )}
              {shift.leave && <LeaveSlot leaveInfo={{...shift.leave, mainType: shift.leave.vacation === true ? "V": shift.leave.leaveType.type}} page={MONTHVIEW_ID}/>}
            </div>
          );
        })}
      {isWorkShiftShown && (
        <WorkShiftConfig2
          shift={userShift}
          shiftIndex={shiftIndex}
          user={user}
          onClose={hideShowWorkShiftHandler}
          showTransferUser={false}
          openFrom={'month-view'}
        />
      )}
    </>
  );
};

export default MonthViewDays;