import React, { useEffect, useState } from "react";
import classes from "./List.module.scss";
import DeleteConfirmationDialog from "../UI/DeleteConfirmationDialog";
import { resetNewLocation, selectNewLocation } from "../../features/currentDataSlice";
import { useDispatch, useSelector } from "react-redux";
import LocationShifts from "./LocationShifts";

const List = ({
  filteredData,
  selectedItem,
  selectLocationHandler,
  addNewLocationHandler,
  deleteLocationHandler,
  handleSearch
}) => {
  const [searchLocation, setSearchLocation] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [deleteItemId, setdeleteItemId] = useState();

  const newLocation = useSelector(selectNewLocation);

  const dispatch = useDispatch();

  useEffect(() => {
    if (newLocation > 0) {
      let element = document.getElementById(`location${newLocation}`);
      if (element) {
        element.scrollIntoView({ block: "center", behavior: "auto" });
        element.classList.add(classes.blinkEffect);
        setTimeout(() => {
          element.classList.remove(classes.blinkEffect);
        }, 1800);
        dispatch(resetNewLocation());
      }
    }
  }, [newLocation, filteredData])

  const handleCreateLocation = () => {
    addNewLocationHandler();
  };

  const handleEditItem = (item) => {
    selectLocationHandler(item);
  };

  const handleDeleteItem = (item) => {
    if (item.id) {
      setShowDialog(true);
      setdeleteItemId(item.id)
    } else {
      deleteLocationHandler(item.id, true);
    }
  };

  const onConfirmDelete = () => {
    setShowDialog(false);
    deleteLocationHandler(deleteItemId);
  }

  const onCancel = () => {
    setShowDialog(false);
  }

  const handleSearchForm = (value) => {
    handleSearch(value)
  };

  const [editShifts, setEditShifts] = useState(false);

  const editRequiredShifts = (id) => {
    setEditShifts(id);
  }

  return (
    <>
      <div className={classes.header}>
        <div className={classes.searchInput}>
          <input
            placeholder="Search"
            className={`${classes.inputUser}`}
            value={searchLocation}
            onChange={(e) => {
              setSearchLocation(e.target.value);
              handleSearchForm(e.target.value);
            }}
          ></input>
          <span className={`icon-search ${classes.searchIcon}`}></span>
        </div>
        {/* add permissions */}
        <button className={classes.addNewItem} onClick={handleCreateLocation}>
          <span className={`icon-location ${classes.icon}`}></span>
          <div>New Location</div>
        </button>
      </div>
      <div className={classes.cardsContainer}>
        {filteredData && filteredData.length > 0 &&
          filteredData.map((item, index) => (
            <div
              id={`location${item.id}`}
              key={item.id}
              className={`${classes.userCard} ${selectedItem.id === item.id ? classes.selected : ""
                }`}
              onClick={() => handleEditItem(item, index)}
            >
              <div className={classes.cardInfo}>
                <span
                  className={`icon-location ${classes.iconLocation}`}
                ></span>
                <div>
                  <span className={classes.title}>
                    {item.value + " " + item.label}
                  </span>
                  <div className={classes.subtitle}>
                    <div>
                      <span>Sites: </span>
                      {item.sites.map((site, index) => (
                        <span key={index} >{site.name + " "}</span>
                      ))}
                    </div>
                    <div>
                      <span>Time Zone: {item.timeZone}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.locationButtons}>
                <div
                  onClick={()=>editRequiredShifts(item.id)}
                  className={classes.deleteIcon}
                >
                  <span className="icon-required-shifts"></span>
                </div>
                <div
                  onClick={() => handleDeleteItem(item)}
                  className={classes.deleteIcon}
                >
                  <span className="icon-delete"></span>
                </div>
              </div>
            </div>
          ))}
      </div>
      {showDialog && (
        <DeleteConfirmationDialog
          message={"Do you want to delete the selected location?"}
          onConfirm={onConfirmDelete}
          onCancel={onCancel}
        />
      )}
      <LocationShifts locationId={editShifts} onClose={()=>setEditShifts(false)}/>
    </>
  );
};

export default List;