import React from "react";
import classes from "./LeaveSlot.module.scss";
import { HOME_ID, MONTHVIEW_ID } from "../services/global";
import { Tooltip } from "@mui/material";

const LeaveSlot = ({ leaveInfo, page = MONTHVIEW_ID }) => {
  const leave = leaves[leaveInfo.mainType];
  return (leave &&
    <div className={classes.leaveContainer} style={{ color: leave.color ?? '' }}>
      {page === MONTHVIEW_ID ?
        <Tooltip
          title={leave.label ?? ''}
          placement="bottom"
          arrow>
          <span className={leave.iconName}></span>
        </Tooltip> :
        <span className={leave.iconName}></span>
      }
      {page === HOME_ID && <span className={classes.leaveTitle}>{leave.label}</span>}
    </div>
  )
}

const leaves = {
  V: { iconName: 'icon-vacation', label: 'Vacation', color: '#FF9F1C' },
  M: { iconName: 'icon-leave', label: 'Leave', color: '#3F7CAC' },
  S: { iconName: 'icon-sick', label: 'Sick Leave', color: '#D17B88' },
  T: { iconName: 'icon-training', label: 'Training', color: '#00CA9A' }
}

export default LeaveSlot;