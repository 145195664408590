import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./ShiftPatternList.module.scss";
import { loadAppliedPatterns, selectedPatternHandler, selectedPatternState } from "../../features/shiftPatternSlice";
import { useState } from "react";
import { useEffect } from "react";

const ShiftPatternList = ({ patterns }) => {
  const selectedPattern = useSelector(selectedPatternState);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [patternList, setPatternList] = useState([]);
  const [filteredPatterns, setFilteredPatterns] = useState([]);

  const shiftPatternHandler = (pattern) => {
    dispatch(selectedPatternHandler(pattern));
    dispatch(loadAppliedPatterns(pattern.id));
  }

  useEffect(() => {
    setPatternList(addSearchValue(patterns ?? []));
  }, [patterns]);

  useEffect(() => {
    setFilteredPatterns(patternList.filter(pattern => pattern.searchValues.some(value => value.includes(searchQuery))))
  }, [patternList, searchQuery])

  const query = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  }

  return (
    <>
      <input
        className={classes.inputPattern}
        type="text"
        placeholder="Search Pattern"
        onChange={query}
      />
      <div className={classes.shiftList}>
        {filteredPatterns.map((pattern) => {
          return (
            <div onClick={() => shiftPatternHandler(pattern)} key={pattern.id}
              className={selectedPattern?.id === pattern.id ? `${classes.patternCard} ${classes.selectedPattern}` : `${classes.patternCard}`}>
              <div className={classes.info}>
                <div>
                  <span className={classes.name}>{pattern.patternName}</span>
                </div>
                <span className={classes.daySpan}>
                  Day Span {pattern.daySpan}
                </span>
              </div>
              <span className={`icon-right-arrow  ${classes.arrowIcon}`}></span>
            </div>
          );
        })}
        {filteredPatterns.length < 1 && 
          <div className={classes.placeholder}>
            No patterns found
          </div>
        }
      </div>
    </>
  );
};

const addSearchValue = (patternList) => {
  return patternList.map(pattern => ({ ...pattern, searchValues: [pattern.patternName.toString().toLowerCase(), `day span ${pattern.daySpan.toString()}`] }))
}

export { ShiftPatternList };
