import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./CreateNewShift.module.scss";
import useScheduleTimesAndSites from "../hooks/useScheduleTimesAndSites";
import { DayCard } from "./DayCard";
import {
  addDayCard,
  deleteDayCard,
  dayCards,
  dayCardCounter,
  setDayCardValue,
  selectedPatternState
} from "../../features/shiftPatternSlice";
import { resourcePermissions, checkPermission } from "../../features/resourcePepermissionsSlice";
import { allLocations, selectedLocationState } from "../../features/currentDataSlice";
import MultiOptionDialog from "../UI/MultiOptionDialog";


const CreateNewShift = ({ selectedShiftPattern, onSave, onDeleteShiftPattern }) => {
  const dispatch = useDispatch();
  const currentResourcePermissions = useSelector(resourcePermissions('pattern'));
  const [patternName, setPatternName] = useState("New Pattern")
  const dayCardArray = useSelector(dayCards);
  const cardCounter = useSelector(dayCardCounter);
  const selectedLocation = useSelector(selectedLocationState);
  const locations = useSelector(allLocations);
  const currentLocation = locations.find(l => l.id === selectedLocation.id);

  const { sites, schedules, reloadSites } = useScheduleTimesAndSites(
    null,
    selectedLocation
  );

  useEffect(() => {
    reloadSites();
  }, [dayCardArray])

  const dayCounterHandler = (action) => {
    const day = {
      day: cardCounter + 1,
      site: null,
      scheduleTime: null,
      status: false
    };
    if (action === 1) {
      dispatch(addDayCard(day));
    } else {
      if (cardCounter > 0) {
        dispatch(deleteDayCard());
      }
    }
  };

  const saveNewPattern = () => {
    const newPattern = {
      id: selectedShiftPattern?.id ? selectedShiftPattern.id : null,
      patternName: patternName,
      days: dayCardArray,
      daySpan: dayCardArray.length,
    };
    onSave(newPattern);
  };

  const handlePatternNameChange = (event) => {
    setPatternName(event.target.value);
  };

  useEffect(() => {
    if (selectedShiftPattern === null && cardCounter === 0) {
      for (let i = 0; i < 7; i++) {
        const day = {
          day: i + 1,
          site: null,
          scheduleTime: null,
          status: false,
        };
        dispatch(addDayCard(day));
      }
    }
  }, [selectedShiftPattern]);

  useEffect(() => {
    if (selectedShiftPattern !== null) {
      setPatternName(selectedShiftPattern.patternName);
      dispatch(setDayCardValue({ arrayData: selectedShiftPattern.days, length: selectedShiftPattern.days.length }));
    } else {
      setPatternName("New Pattern");
    }
  }, [selectedShiftPattern])

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onDelete = () => {
    setShowDeleteDialog(true);
  }

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
  }

  const onConfirmDelete = () => {
    onDeleteShiftPattern(selectedShiftPattern.id)
  }

  return (
    <>
      <div className={classes.header}>
        {!selectedShiftPattern ? (
          <span className={classes.title}>New Shift Patterns</span>
        ) : (
          <span className={classes.title}>Shift Pattern</span>
        )}
        <span className={classes.info}>
          Apply the created pattern to a range of dates and to a group of
          employees.
        </span>
        <span className={`icon-information1 ${classes.iconInfo}`}></span>
        <button onClick={saveNewPattern} className={classes.saveBtn}>
          Save
        </button>
        {selectedShiftPattern && checkPermission(currentResourcePermissions, 'delete-pattern') && <span onClick={onDelete} className={`icon-delete ${classes.iconDelete}`}></span>}
      </div>
      <div className={classes.nameContainer}>
        <span>Pattern Name</span>
        <input
          className={classes.inputPattern}
          type="text"
          placeholder="Name"
          value={patternName}
          onChange={handlePatternNameChange}
        ></input>
      </div>
      <div className={classes.daySpanContainer}>
        <span className={classes.title}>Day Span</span>
        <span className={classes.info}>Number of days</span>
        <span
          onClick={() => dayCounterHandler(1)}
          className={`icon-more ${classes.addBtn}`}
        ></span>
        <span
          onClick={() => dayCounterHandler(0)}
          className={`icon-less ${classes.addBtn}`}
        ></span>
        <input
          className={classes.inputPattern}
          type="text"
          placeholder="Days"
          value={cardCounter}
          readOnly
        ></input>
      </div>
      <div className={classes.configureDaysContianer}>
        <span className={classes.title}>Day - Configuration</span>
        <div className={classes.daysContainer}>
          {dayCardArray.map((data) => (
            <DayCard
              key={data.day}
              dayData={data}
              currentLocation={currentLocation}
              sites={sites}
              schedules={schedules}
            />
          ))}
        </div>
      </div>
      {showDeleteDialog &&
        <MultiOptionDialog
          message={`Are you sure you want to delete pattern "${selectedShiftPattern.patternName}"?`}
          options={[
            { label: 'Delete', callback: onConfirmDelete },
            { label: 'Cancel', callback: onCancelDelete },
          ]}
        />
      }
    </>
  );
};

export { CreateNewShift };
