import React, { useEffect, useState } from "react";
import classes from "./ShiftPattern.module.scss";
import { ShiftPatternList } from "./ShiftPatternList";
import { CreateNewShift } from "./CreateNewShift";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShiftPatterns,
  selectedPatternState,
  shiftPatternList,
  clearShiftPatternComponent,
  saveUpdateShiftPattern,
  showDialogState,
  showDialogHandler,
  deleteShiftPatternAction
} from "../../features/shiftPatternSlice";
import { ApplyPatternModal } from "./ApplyPatternModal";
import InformationDialog from "../UI/InformationDialog";
import { resourcePermissions, fetchResourcePermissions, checkPermission } from "../../features/resourcePepermissionsSlice";

const ShiftPattern = () => {
  const patternsList = useSelector(shiftPatternList);
  const [openNewShift, setOpenNewShift] = useState(false);
  const [showApplyPatternModal, setShowApplyPatternModal] = useState(false);
  const selectedShiftPattern = useSelector(selectedPatternState);
  const showDialog = useSelector(showDialogState)
  const dispatch = useDispatch();
  const currentResourcePermissions = useSelector(resourcePermissions('pattern'));

  const openNewShiftHandler = () => {
    dispatch(clearShiftPatternComponent());
  };

  useEffect(() => {
    if(selectedShiftPattern === null) {
      setOpenNewShift(true)
     } else {
      setOpenNewShift(false)
    };
  }, [selectedShiftPattern])

  const closeNewShiftHandler = () => {
    dispatch(clearShiftPatternComponent());
    setOpenNewShift(false);
  };

  const applyPatternToggle = () => {
    setShowApplyPatternModal(!showApplyPatternModal);
  };

  useEffect(() => {
    dispatch(fetchShiftPatterns());
    dispatch(fetchResourcePermissions('pattern'))
  }, [dispatch]);

  const saveNewShiftHandler = (pattern) => {
    dispatch(saveUpdateShiftPattern(pattern));
  }

  const dialogHandler = () => {
    dispatch(showDialogHandler({ state: false, message: "", code: -1 }));
    if (showDialog.code === 200 && showApplyPatternModal) {
    }
  }

  const onDeleteShiftPattern = (patternId) => {
    setOpenNewShift(false);
    dispatch(deleteShiftPatternAction(patternId));
    dispatch(clearShiftPatternComponent());
  }

  useEffect(() => {
    openNewShiftHandler();
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.newShiftContainer}>
          <span
            className={`icon-user-shift-pattern ${classes.userIcon}`}
          ></span>
          <span className={classes.title}>Shift Patterns</span>
          {checkPermission(currentResourcePermissions, 'create-pattern') && <button onClick={openNewShiftHandler}>+ New Shift Pattern</button>}
        </div>
        {(selectedShiftPattern !== null) && (checkPermission(currentResourcePermissions, 'apply-pattern')) &&
          <button onClick={applyPatternToggle} className={classes.applyBtn}>
            Apply Pattern
          </button>
        }
        {/* {(openNewShift || selectedShiftPattern !== null) && (
          <button
            onClick={closeNewShiftHandler}
            className={`${classes.applyBtn} ${classes.discardBtn}`}
          >
            Discard
          </button>
        )} */}
      </div>
      <div className={classes.body}>
        <div className={classes.patternList}>
          {patternsList && <ShiftPatternList patterns={patternsList} />}
        </div>
        <div className={classes.newPattern}>
          {(openNewShift === true || selectedShiftPattern !== null) && (
            <CreateNewShift
              selectedShiftPattern={selectedShiftPattern}
              onSave={saveNewShiftHandler}
              onDeleteShiftPattern={onDeleteShiftPattern}
            />
          )}
        </div>
      </div>
      {showApplyPatternModal && (
        <ApplyPatternModal onClose={applyPatternToggle}></ApplyPatternModal>
      )}
      {showDialog.state && (
        <InformationDialog message={showDialog.message} onCancel={dialogHandler} ></InformationDialog>
      )}
    </div>
  );
};

export { ShiftPattern };
